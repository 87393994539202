// eslint-disable-next-line import/prefer-default-export
export const amexTerms = {
  title: "SpotOn terms & policies",
  noMore: true,

  subverticalInfo: [
    {
      iconImg: "terms.png",
      subverticalName: "Terms of Services.",
      links: [
        {
          linkTitle: "Merchants",
          linkTarget: "/legal/merchant-terms",
        },
        {
          linkTitle: "Users",
          linkTarget: "/legal/user-terms",
        },
        {
          linkTitle: "Point-of-Sale",
          linkTarget: "/legal/point-of-sale-terms",
        },
        {
          linkTitle: "SpotOn Website",
          linkTarget: "/legal/website-terms",
        },
        {
          linkTitle: "Payments",
          linkTarget: "https://spoton.com/SpotOn-AMEXOptBlue-T&C.pdf",
        },
        {
          linkTitle: "SpotOn Reserve",
          linkTarget: "/legal/reserve-terms",
        },
        {
          linkTitle: "SpotOn Teamwork",
          linkTarget: "/legal/teamwork-terms",
        },
      ],
      subverticalContent:
        "Read our Terms of Service for SpotOn merchants and consumers.",
    },
    {
      iconImg: "policies.png",
      subverticalName: "Policies",
      subverticalContent:
        "Explore our privacy statement for details on how we collect and use data.",
      links: [
        {
          linkTitle: "Merchant Privacy",
          linkTarget: "/legal/merchant-privacy",
        },
        {
          linkTitle: "Accessibility",
          linkTarget: "/legal/accessibility-statement",
        },
        {
          linkTitle: "Consumer Privacy",
          linkTarget: "/legal/consumer-privacy",
        },
        {
          linkTitle: "SMS Terms",
          linkTarget: "/legal/sms-terms-and-conditions",
        },
      ],
    },
  ],
};

export const legalListCPP = [
  {
    linkName: "What information SpotOn collects from you",
    linkTo: "/consumer-privacy#info",
  },
  {
    linkName:
      "How SpotOn uses and may disclose information it obtains through a SpotOn \n" +
      "Website, SpotOn Mobile or the Services",
    linkTo: "/consumer-privacy#disclosure",
  },
  {
    linkName: "What choices you have about how SpotOn treats your information",
    linkTo: "/consumer-privacy#choices",
  },
  {
    linkName: "How SpotOn protects your privacy ",
    linkTo: "/consumer-privacy#privacy",
  },
  {
    linkName:
      "What SpotOn does about children visiting a SpotOn Website or Spoton Mobile",
    linkTo: "/consumer-privacy#children",
  },
  {
    linkName: "How comments you may make via SpotOn are handled;",
    linkTo: "/consumer-privacy#comments",
  },
  {
    linkName: "How you can contact SpotOn",
    linkTo: "/consumer-privacy#contact",
  },
];

export const legalListMPP = [
  {
    linkName: "INFORMATION WE COLLECT ABOUT YOU",
    linkTo: "/legal/merchant-privacy#info-you-provide",
  },
  {
    linkName: "INFORMATION WE MAY COLLECT ABOUT YOUR CUSTOMERS",
    linkTo: "/legal/merchant-privacy#info-your-customers",
  },
  {
    linkName: "SOURCES OF INFORMATION WE COLLECT ABOUT YOU",
    linkTo: "/legal/merchant-privacy#info-sources",
  },
  {
    linkName: "HOW WE USE YOUR INFORMATION ",
    linkTo: "/legal/merchant-privacy#how-we-use-it",
  },
  {
    linkName: "WHEN AND WITH WHOM WE SHARE YOUR INFORMATION",
    linkTo: "/legal/merchant-privacy#sharing",
  },
  {
    linkName: "COOKIES AND OTHER SIMILAR TECHNOLOGIES",
    linkTo: "/legal/merchant-privacy#cookies",
  },
  {
    linkName: "THIRD-PARTY ANALYTICS SERVICES",
    linkTo: "/legal/merchant-privacy#third-parties",
  },
  {
    linkName: "ONLINE TRACKING AND INTEREST-BASED ADVERTISING",
    linkTo: "/legal/merchant-privacy#online-tracking",
  },
  {
    linkName: "HOW LONG WE KEEP YOUR INFORMATION",
    linkTo: "/legal/merchant-privacy#how-long",
  },
  {
    linkName: "YOUR CHOICES",
    linkTo: "/legal/merchant-privacy#choices",
  },
  {
    linkName: "ONLINE BEHAVIORAL OR INTEREST-BASED ADVERTISING",
    linkTo: "/legal/merchant-privacy#online-advertising",
  },
  {
    linkName: "RIGHTS OF CALIFORNIA RESIDENTS",
    linkTo: "/legal/merchant-privacy#ca-residents",
  },
  {
    linkName: "CHILDREN’S PERSONAL INFORMATION",
    linkTo: "/legal/merchant-privacy#children",
  },
  {
    linkName: "SECURITY",
    linkTo: "/legal/merchant-privacy#security",
  },
  {
    linkName: "STORAGE AND PROCESSING",
    linkTo: "/legal/merchant-privacy#storage",
  },
  {
    linkName: "CHANGES TO THIS PRIVACY POLICY",
    linkTo: "/legal/merchant-privacy#changes",
  },
  {
    linkName: "CONTACT",
    linkTo: "/legal/merchant-privacy#contact",
  },
];
